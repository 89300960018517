import { ScreenSize } from 'glints-aries';
import { Link } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  grid-template-rows: auto;
  gap: 20px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 6fr;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const StyledLink = styled(Link)`
  justify-content: center;
  text-align: center;
  text-decoration: underline;
`;
