import { ScreenSize } from 'glints-aries';
import { Icon } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
`;

export const StyledIcon = styled(Icon)`
  fill: #ffffff;
  &:hover {
    fill: #0baeec;
  }
`;

export const IconLink = styled.a`
  padding: 2px;
  margin: 5px;
  margin-right: 10px;
  text-decoration: inherit;
  color: inherit;
  cursor: auto;

  @media (min-width: ${ScreenSize.mobileL}px) and (max-width: ${ScreenSize.tablet}px) {
    margin-right: 4px;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
