import React from 'react';
import { Builder } from '@builder.io/react';

import * as S from './PopularJobs.sc';

type BuilderProps = {
  jobs: {
    text: string;
    isHighlighted: boolean;
    highlightColor: string;
    highlightIcon: string;
    link: string;
  }[];
};

const PopularJobs = (props: BuilderProps) => {
  if (!props.jobs) return null;

  return (
    <S.Wrapper>
      {props.jobs.map((job, idx) => (
        <a
          key={idx}
          href={job.link}
          className="job-item"
          rel="noreferrer"
          target="_blank"
        >
          <S.JobCard
            isHighlighted={job.isHighlighted}
            color={job.highlightColor}
          >
            {job.isHighlighted && (
              <S.JobHighlightIcon src={job.highlightIcon} />
            )}
            <S.JobText
              isHighlighted={job.isHighlighted}
              highlightColor={job.highlightColor}
              variant="caption"
            >
              {job.text}
            </S.JobText>
          </S.JobCard>
        </a>
      ))}
    </S.Wrapper>
  );
};

Builder.registerComponent(PopularJobs, {
  name: 'v2 Popular Jobs',
  inputs: [
    {
      name: 'jobs',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'text',
        },
        {
          name: 'isHighlighted',
          type: 'boolean',
          friendlyName: 'Highlight Job',
        },
        {
          name: 'highlightColor',
          type: 'color',
        },
        {
          name: 'highlightIcon',
          type: 'file',
        },
        {
          name: 'link',
          type: 'text',
        },
      ],
    },
  ],
});
