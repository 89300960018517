import React from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Speaker } from 'src/global/models/User';

import * as S from './EventCard.sc';

type Props = {
  speakers: Speaker[];
};

type Role = {
  title: string;
  organization: string;
};

const SpeakerRole = ({ role }: { role: Role }) => {
  const { title, organization } = role;

  return title ? (
    <p>
      {`${title}${organization ? ' at ' : ''}`}
      <b>{organization}</b>
    </p>
  ) : null;
};

const EventSpeaker = ({ speakers = [] }: Props) => {
  if (isEmpty(speakers)) {
    return null;
  }

  const restExpertNumber = speakers.length > 1 ? speakers.length - 1 : null;

  if (isEmpty(speakers)) return null;

  const speaker = speakers[0];

  const {
    id,
    ugcFullName,
    profilePic,
    presentExperiences: experiences,
    expertRating,
  } = speaker;

  const role = isEmpty(experiences) ? null : experiences[0];

  return (
    <div>
      <S.SpeakerContainer>
        <S.UserImageContainer>
          <S.UserImage userID={id} profilePic={profilePic} sizes="32px" />
        </S.UserImageContainer>
        <div>
          <S.SpeakerNameAndRatingContainer>
            <S.SpeakerName>{ugcFullName}</S.SpeakerName>
            {expertRating?.finalRating && (
              <S.SpeakerRating>
                <S.DotSeperator />
                <S.ExpertRatingIcon /> {expertRating?.finalRating.toFixed(1)}
              </S.SpeakerRating>
            )}
          </S.SpeakerNameAndRatingContainer>

          {role && (
            <>
              <S.SpeakerRole>
                <SpeakerRole role={role} />
              </S.SpeakerRole>
            </>
          )}
        </div>
      </S.SpeakerContainer>
      {restExpertNumber && (
        <S.MoreSpeakersText>
          <FormattedMessage
            id="gec-class-card.text-more-expert"
            defaultMessage={`and {restExpertNumber} other {restExpertNumber, plural, =0 {expert} one {expert} other {experts}}`}
            values={{
              restExpertNumber: restExpertNumber,
            }}
          />
        </S.MoreSpeakersText>
      )}
    </div>
  );
};

export default React.memo(EventSpeaker);
