import React from 'react';
import { Builder } from '@builder.io/react';
import { ScreenSize } from 'glints-aries';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import * as S from './ActiveEmployers.sc';

type BuilderProps = {
  logos: { image: string; link: string }[];
  moreLink: string;
};

const ActiveEmployers = (props: BuilderProps) => {
  const isMobile = useMediaQuery(`max-width: ${ScreenSize.mobileL}px`);
  if (!props.logos || props.logos?.length === 0) return <></>;

  return (
    <>
      <S.Wrapper>
        {props.logos.map((logo, idx) => (
          <a key={idx} href={logo.link} target="_blank" rel="noreferrer">
            <S.ImageWrapper>
              <S.Image src={logo.image} alt={logo.link} />
            </S.ImageWrapper>
          </a>
        ))}
        {!isMobile && (
          <S.MoreText>
            <a href={props.moreLink} target="_blank" rel="noreferrer">
              More...
            </a>
          </S.MoreText>
        )}
      </S.Wrapper>
      {isMobile && (
        <S.MoreText>
          <a href={props.moreLink} target="_blank" rel="noreferrer">
            More...
          </a>
        </S.MoreText>
      )}
    </>
  );
};

Builder.registerComponent(ActiveEmployers, {
  name: 'v2 Active Employers',
  inputs: [
    {
      name: 'logos',
      friendlyName: 'Employer Logos',
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file',
        },
        {
          name: 'link',
          type: 'text',
        },
      ],
    },
    {
      name: 'moreLink',
      friendlyName: 'Redirect "More" text to',
      type: 'text',
    },
  ],
});

export default ActiveEmployers;
