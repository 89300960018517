import React, { useState } from 'react';
import { Builder } from '@builder.io/react';
import { Tabs } from 'glints-aries/lib/@next';
import { defineMessage, useIntl } from 'react-intl';

import * as S from './PopularSearches.sc';

type Item = {
  searchTerm: string;
  url: string;
};

type BuilderProps = {
  rolesList: Item[];
  categoryList: Item[];
  locationList: Item[];
  keywordList: Item[];
};

const TabContent = ({ itemList }: { itemList: Item[] }) => {
  if (!itemList) return <></>;

  return (
    <S.Wrapper>
      {itemList.map((item, idx: number) => (
        <S.TextBlock key={idx}>
          <a
            href={item.url}
            style={{ color: 'inherit' }}
            target="_blank"
            rel="noreferrer"
          >
            {item.searchTerm}
          </a>
          {idx !== itemList.length - 1 && <>,</>}
        </S.TextBlock>
      ))}
    </S.Wrapper>
  );
};

const PopularSearches = (props: BuilderProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState(0);

  const tabs = [
    {
      id: 'tab-1',
      content: intl.formatMessage(
        defineMessage({
          id: 'homepage.popular_searches.job_roles',
          defaultMessage: 'Job Roles',
        })
      ),
    },
    {
      id: 'tab-2',
      content: intl.formatMessage(
        defineMessage({
          id: 'homepage.popular_searches.job_category',
          defaultMessage: 'Job Category',
        })
      ),
    },
    {
      id: 'tab-3',
      content: intl.formatMessage(
        defineMessage({
          id: 'homepage.popular_searches.location',
          defaultMessage: 'Location',
        })
      ),
    },
    {
      id: 'tab-4',
      content: intl.formatMessage(
        defineMessage({
          id: 'homepage.popular_searches.keyword',
          defaultMessage: 'Keyword',
        })
      ),
    },
  ];

  const tabContent = [
    <TabContent key={0} itemList={props.rolesList} />,
    <TabContent key={1} itemList={props.categoryList} />,
    <TabContent key={2} itemList={props.locationList} />,
    <TabContent key={3} itemList={props.keywordList} />,
  ];

  return (
    <Tabs tabs={tabs} onSelected={(i) => setSelected(i)}>
      {tabContent[selected]}
    </Tabs>
  );
};

Builder.registerComponent(PopularSearches, {
  name: 'v2 Popular Searches',
  description: 'PopularSearches for new homepage',
  inputs: [
    {
      name: 'rolesList',
      friendlyName: 'Job Roles',
      type: 'list',
      subFields: [
        {
          name: 'searchTerm',
          friendlyName: 'Display Text',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
    },
    {
      name: 'categoryList',
      friendlyName: 'Job Category',
      type: 'list',
      subFields: [
        {
          name: 'searchTerm',
          friendlyName: 'Display Text',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
    },
    {
      name: 'locationList',
      friendlyName: 'Location',
      type: 'list',
      subFields: [
        {
          name: 'searchTerm',
          friendlyName: 'Display Text',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
    },
    {
      name: 'keywordList',
      friendlyName: 'Keyword',
      type: 'list',
      subFields: [
        {
          name: 'searchTerm',
          friendlyName: 'Display Text',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
    },
  ],
});

export default PopularSearches;
