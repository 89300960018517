import React from 'react';
import { Builder } from '@builder.io/react';
import NoSSR from '@mpth/react-no-ssr';
import { ScreenSize } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import * as S from './Testimonials.sc';

const imageHeight = 120;

type BuilderProps = {
  image: string;
  content: string;
  name: string;
  age: string;
  jobTitle: string;
};

const TestimonialsMobile = (props: BuilderProps) => {
  return (
    <NoSSR>
      <S.WrapperMobile>
        <S.UserLogoImageDivMobile>
          <S.UserLogoImage
            src={props.image}
            alt="Testimonial user"
            imageHeight={60}
          />
        </S.UserLogoImageDivMobile>
        <S.CardContentMobile>
          <Typography variant="body1">{props.content}</Typography>
          <S.CardBottomMobile>
            <Typography variant="overline">
              {props.name}, {props.age}, {props.jobTitle}
            </Typography>
          </S.CardBottomMobile>
        </S.CardContentMobile>
      </S.WrapperMobile>
    </NoSSR>
  );
};

const TestimonialsTablet = (props: BuilderProps) => {
  const _imageSize = 100;

  return (
    <NoSSR>
      <S.WrapperTablet imageHeight={_imageSize}>
        <S.UserLogoDivTablet imageHeight={_imageSize}>
          <S.UserLogoImage
            src={props.image}
            alt="Testimonial user"
            imageHeight={_imageSize}
          />
        </S.UserLogoDivTablet>
        <S.UserContentTablet imageHeight={_imageSize}>
          <S.UserContentTextTablet>
            <S.UserContentTextTypographyTablet variant="body1">
              {props.content}
            </S.UserContentTextTypographyTablet>
          </S.UserContentTextTablet>
          <S.UserContentBottomTablet>
            <Typography
              variant="overline"
              color="#666"
              style={{ letterSpacing: 1.5 }}
            >
              {props.name}, {props.age}, {props.jobTitle}
            </Typography>
          </S.UserContentBottomTablet>
        </S.UserContentTablet>
      </S.WrapperTablet>
    </NoSSR>
  );
};

const Testimonials = (props: BuilderProps) => {
  const isMobile = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${ScreenSize.mobileL}px) and (max-width: 991px)`
  );

  if (!props.name || !props.image) return <></>;

  if (isMobile) return <TestimonialsMobile {...props} />;

  if (isTablet) return <TestimonialsTablet {...props} />;

  return (
    <NoSSR>
      <S.WrapperDesktop>
        <S.UserLogoDivDesktop imageHeight={imageHeight}>
          <S.UserLogoImage
            src={props.image}
            alt="Testimonial user"
            imageHeight={imageHeight}
          />
        </S.UserLogoDivDesktop>
        <S.UserContentDesktop>
          <div style={{ marginTop: imageHeight / 2, height: 120 }}>
            <Typography variant="body1">{props.content}</Typography>
          </div>
          <S.UserContentBottomDesktop>
            <Typography variant="overline" color="#666666">
              {props.name}, {props.age} <br />
              {props.jobTitle}
            </Typography>
          </S.UserContentBottomDesktop>
        </S.UserContentDesktop>
      </S.WrapperDesktop>
    </NoSSR>
  );
};

Builder.registerComponent(Testimonials, {
  name: 'v2 Testimonials',
  inputs: [
    {
      name: 'image',
      type: 'file',
    },
    {
      name: 'content',
      type: 'text',
      max: 160, // TODO: add onchange to enforce max content limit
    },
    {
      name: 'name',
      type: 'text',
      max: 10,
    },
    {
      name: 'age',
      type: 'text',
      helperText: 'For example, "24 years" or "24 tahun"',
    },
    {
      name: 'jobTitle',
      type: 'text',
      max: 20,
    },
  ],
});

export default Testimonials;
