import { Breakpoints, Link, Modal, Typography } from 'glints-aries/lib/@next';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    max-width: 620px;
    height: 100%;
  }

  @media (max-width: ${Breakpoints.large}) {
    .modal-content: {
      overflow: auto;
      width: 100%;
    }
  }
`;

export const TabsWrapper = styled.div`
  .tabs-header-container {
    margin: 0;
  }

  .tab-item-content {
    display: flex;
    margin: 64px 90px;
    justify-content: center;
  }
`;

export const ContentDescriptionWrapper = styled.div`
  padding: ${space8};
  margin-right: ${space16};
`;

export const Description = styled(Typography)`
  display: inline;
`;

export const AppLink = styled(Link)`
  display: inline-block;
`;

export const AppStoreWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const AppStoreIcon = styled.img`
  margin-right: 15px;
  height: 40px;
  width: 40px;
`;

export const QRImage = styled.img`
  margin: 15px;
  width: 150px;
  height: 150px;
`;
