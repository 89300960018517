import * as yup from 'yup';

import { Event } from 'src/global/models';
import { State } from 'src/global/store';

import { getFeature, getFeatureValue } from '../Unleash/Selectors';
import { FeatureFlags, Variants } from './FeatureFlags';

const getEventsState = (state: State) => state.events;

export const getCurrentEvents = (state: State) => getEventsState(state).value;
export const getEventsError = (state: State) => getEventsState(state).error;
export const getEventsCurrentPage = (state: State) =>
  getEventsState(state).currentPage;
export const getEventsFetchingMoreEvents = (state: State) =>
  getEventsState(state).fetchingMoreEvents;
export const getEventsLastFetched = (state: State) =>
  getEventsState(state).lastFetched;
export const getEventsLoading = (state: State) => getEventsState(state).loading;
export const getEventsTotal = (state: State) => getEventsState(state).total;

export const getExpertClassCategories = (state: State) =>
  state.events.categories.value;

export const getIsExpertClassCategoriesLoading = (state: State) =>
  state.events.categories.loading;

export const getGECHomepageExperimentVariant = (state: State) =>
  getFeatureValue(
    state,
    FeatureFlags.GECHomepageExperiment,
    Variants[FeatureFlags.GECHomepageExperiment].ORIGINAL
  );

export const getIsDefaultPromoCodeEnabled = (state: State): boolean =>
  Boolean(getFeature(state, FeatureFlags.dstExpertClassDefaultPromoCode));

export const getExpertClassShortIdsForTimeSurvey = (
  state: State
): Event['shortId'][] => {
  try {
    return yup
      .array(yup.string())
      .validateSync(
        JSON.parse(
          getFeatureValue(state, FeatureFlags.dstExpertClassTimeSurvey, '[]')
        )
      );
  } catch (error) {
    /* empty */
  }
  return [];
};
