import React, { useState } from 'react';
import { Builder } from '@builder.io/react';
import NoSSR from '@mpth/react-no-ssr';
import { ScreenSize } from 'glints-aries';
import { Carousel as GlintsCarousel } from 'glints-aries/lib/@next';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import { BannerImage, CarouselWrapper } from './Carousel.sc';
import GlintsAppModal from './GlintsAppModal';

type BuilderProps = {
  images: {
    bannerDesktop: string;
    bannerDesktopUrl: string;
    bannerTablet: string;
    bannerTabletUrl: string;
    bannerMobile: string;
    bannerMobileUrl: string;
    onClick?: React.MouseEventHandler<HTMLImageElement>;
    openModal: boolean;
    useImageUrls: boolean;
    url: string;
  }[];
};

type CarouselImage = BuilderProps['images'][0];

const Carousel = (props: BuilderProps) => {
  const [showGlintsAppModal, setShowGlintsAppModal] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${ScreenSize.mobileL}px) and (max-width: 991px)`
  );
  const isDesktop = !isMobile && !isTablet;

  const handleClickAppBanner = () => {
    setShowGlintsAppModal(true);
  };

  const getResponsiveBanner = (img: CarouselImage) => {
    return {
      builderUrl: isMobile
        ? img.bannerMobile
        : isTablet
        ? img.bannerTablet
        : img.bannerDesktop,
      directUrl: isMobile
        ? img.bannerMobileUrl
        : isTablet
        ? img.bannerTabletUrl
        : img.bannerDesktopUrl,
    };
  };

  return (
    <NoSSR>
      <CarouselWrapper>
        <GlintsCarousel
          autoRotate={true}
          duration={5000}
          showArrows={true}
          width="100vw"
        >
          {props.images.map((img, idx) => {
            const { builderUrl, directUrl } = getResponsiveBanner(img);
            return (
              <a
                key={idx}
                {...((!isDesktop || !img.openModal) && { href: img.url })}
                target="_blank"
                rel="noreferrer"
              >
                <BannerImage
                  src={img.useImageUrls ? directUrl : builderUrl}
                  {...(img.openModal &&
                    isDesktop && { onClick: handleClickAppBanner })}
                />
              </a>
            );
          })}
        </GlintsCarousel>
        <GlintsAppModal
          isOpen={showGlintsAppModal}
          onClose={() => setShowGlintsAppModal(false)}
        />
      </CarouselWrapper>
    </NoSSR>
  );
};

Builder.registerComponent(Carousel, {
  name: 'v2 Carousel',
  inputs: [
    {
      name: 'images',
      type: 'list',
      subFields: [
        {
          name: 'bannerDesktop',
          type: 'file',
          helperText: 'Image to use for desktop devices',
        },
        {
          name: 'bannerDesktopUrl',
          type: 'text',
          friendlyName: 'Direct Link (desktop)',
          helperText: 'Direct link to image, is not optimized by Builder.io',
        },
        {
          name: 'bannerTablet',
          type: 'file',
        },
        {
          name: 'bannerTabletUrl',
          type: 'text',
          friendlyName: 'Direct Link (tablet)',
          helperText: 'Direct link to image, is not optimized by Builder.io',
        },
        {
          name: 'bannerMobile',
          type: 'file',
        },
        {
          name: 'bannerMobileUrl',
          type: 'text',
          friendlyName: 'Direct link (mobile)',
          helperText: 'Direct link to image, is not optimized by Builder.io',
        },
        {
          name: 'url',
          type: 'text',
          helperText: 'Dynamic link for mobile devices',
        },
        {
          name: 'openModal',
          type: 'boolean',
          helperText:
            'When ON, clicking the banner opens a modal (only on desktop)',
        },
        {
          name: 'useImageUrls',
          type: 'boolean',
          helperText:
            'ENSURE "Direct links" above are set before enabling this. Will use direct link instead of uploaded files to bypass Builder.io optimisation',
        },
      ],
    },
  ],
});

export default Carousel;
