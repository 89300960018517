export enum FeatureFlags {
  GECHomepageExperiment = 'dstExperimentGECHomepage',
  dstExpertClassDefaultPromoCode = 'dstExpertClassDefaultPromoCode',
  dstExpertClassTimeSurvey = 'dstExpertClassTimeSurvey',
}

export const Variants = {
  [FeatureFlags.GECHomepageExperiment]: {
    NO_CLASS_TYPE_AND_SEARCH: 'noClassTypeAndSearchBarHeader',
    ORIGINAL: 'originalHomepage',
    CATEGORY_ORIENTED_HOMEPAGE: 'categoryOrientedHomepage',
  },
};
