import { ScreenSize } from 'glints-aries';
import { Tag } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTag = styled(Tag)`
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 86, 140, 0.9);
    border-radius: 4px;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    margin-bottom: 12px;
  }
`;
