import React from 'react';
import { Builder, withChildren } from '@builder.io/react';

import * as S from './MarketingBanner.sc';

type BuilderProps = {
  children: React.ReactNode;
};

const MarketingBanner = (props: BuilderProps) => {
  return <S.Section>{props.children}</S.Section>;
};

Builder.registerComponent(withChildren(MarketingBanner), {
  name: 'v2 Marketing Banner',
});

export default MarketingBanner;
