import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: solid 1px #fff;
  border-radius: 5px;
  gap: 20px;
  padding: 8px 16px;
  padding-right: 20px;
`;

export const LogoImage = styled.img`
  width: 40px;
  @media screen and (min-width: 991px) {
    margin-left: 24px;
  }
`;

export const ButtonText = styled.div`
  font-weight: 700;
  letter-spacing: 1.5px;
`;
