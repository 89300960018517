import { filter, get } from 'lodash';

import {
  JobInterface,
  JobLocation,
  JobLocationBase,
  LocationIdAndLevel,
} from 'src/modules/Opportunity/interface';
import {
  HierarchicalLocation,
  Maybe,
} from 'src/modules/Profile/graphql/hierarchicalLocations';

import { CountryCodes } from '../enums';

const validCountriesForHierarchicalLocation = [
  CountryCodes.ID,
  CountryCodes.VN,
];

export const validCountryForHierarchicalLocation = (
  countryCode: CountryCodes
) => validCountriesForHierarchicalLocation.includes(countryCode);

export const isL1Parent = (parent: { level: number }) => parent.level === 1;

export const isL2Parent = (parent: { level: number }) => parent.level === 2;

export const isCity = (hierarchicalLocation: HierarchicalLocation) =>
  hierarchicalLocation.level === 3;

export const isDistrict = (hierarchicalLocation: HierarchicalLocation) =>
  hierarchicalLocation.level === 4;

export const buildSelectedLocationLabel = (location: HierarchicalLocation) =>
  buildCityProvinceLabel(location);

export const buildLocationFromJob = (
  job: JobInterface,
  levels: number[] = [4, 3, 2]
): string => {
  const { city, country, location } = job;
  if (location) {
    const locations = levels.map((level) => {
      const formatted = filter(
        [location, ...location.parents],
        (parent: JobLocationBase) => parent.level === level
      ).map((parent: JobLocationBase) => parent.formattedName);
      return formatted.length > 0 ? formatted[0] : null;
    });
    return locations.filter((value) => value !== null).join(', ');
  }
  return `${get(city, 'name') ? `${city.name},` : ''} ${get(country, 'name')}`;
};

export const buildCityProvinceCountryLabel = (
  location: HierarchicalLocation
) => {
  const level2 = location.parents?.find((parent) => parent.level === 2);
  const level1 = location.parents?.find((parent) => parent.level === 1);
  return [location?.formattedName, level2?.formattedName, level1?.formattedName]
    .filter((item) => item !== undefined)
    .join(', ');
};

export const buildCityProvinceLabel = (location: HierarchicalLocation) => {
  const level2 = location.parents?.find((parent) => parent.level === 2);
  return [location?.formattedName, level2?.formattedName]
    .filter((item) => item !== undefined)
    .join(', ');
};

/**
 * This is used in WarningModal. The userLocation is at L2 or L3, while jobLocation is at L4.
 * Therefore, we take userLocation and internally compare it with jobLocation's id and parents,
 * excluding L1 (Country Level).
 * The userLocation might be updated to use L4 in the future, so we start comparing from L4.
 *
 * @param location - The location object to compare.
 * @returns LocationIdAndLevel[] - An array of locations from levels [L4, L3, L2].
 */

export const getJobLocationIdsAndLevels = (
  location: JobLocation
): LocationIdAndLevel[] => {
  const result: LocationIdAndLevel[] = [];

  if (location.id && location.level) {
    result.push({ id: location.id, level: location.level });
  }

  if (location.parents) {
    for (const parent of location.parents) {
      if (parent.level === 1) continue;
      result.push({ id: parent.id, level: parent.level });
    }
  }

  return result;
};

export const getIsUserLocationSameWithJobLocation = (
  userLocationId?: string,
  location?: JobLocation
): boolean => {
  if (!location || !userLocationId) return false;
  return getJobLocationIdsAndLevels(location).some(
    (location) => location.id === userLocationId
  );
};

export const getSlugsURLParameter = (
  location: HierarchicalLocation
): string | null => {
  const findParentByLevel = (level: number) =>
    filter(location.parents, (parent) => parent.level === level)[0];

  const slugs: Array<Maybe<string> | undefined> = [
    findParentByLevel(1)?.slug,
    findParentByLevel(2)?.slug,
    findParentByLevel(3)?.slug,
    location.slug,
  ];
  return slugs.filter(Boolean).join('/');
};
