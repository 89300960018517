import { ScreenSize } from 'glints-aries';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const BannerImage = styled.img`
  width: 100vw;
  height: 100%;
  aspect-ratio: 990 / 250;

  @media (min-width: 991px) {
    aspect-ratio: 1440 / 250;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    aspect-ratio: 640 / 250;
  }
`;

export const CarouselWrapper = styled.div`
  background-color: ${Neutral.B99};
  object-fit: contain;
`;
