import React from 'react';
import { Builder } from '@builder.io/react';
import NoSSR from '@mpth/react-no-ssr';
import { isIOS } from 'react-device-detect';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

type BuilderProps = {
  isForIos: boolean;
  androidLogo: string;
  androidUrl: string;
  iosLogo: string;
  iosUrl: string;
};

const DownloadAppButton = (props: BuilderProps) => {
  const isMobileDevice = useMediaQuery(`(max-width: 991px)`);

  function getButtonConfig() {
    if (isMobileDevice) {
      if (isIOS) {
        return {
          logo: props.iosLogo,
          link: props.iosUrl,
          alt: 'Download on the App Store',
        };
      } else {
        return {
          logo: props.androidLogo,
          link: props.androidUrl,
          alt: 'Get it on Google Play',
        };
      }
    } else if (props.isForIos) {
      return {
        logo: props.iosLogo,
        link: props.iosUrl,
        alt: 'Download on the App Store',
      };
    } else {
      return {
        logo: props.androidLogo,
        link: props.androidUrl,
        alt: 'Get it on Google Play',
      };
    }
  }

  const buttonConfig = getButtonConfig();

  return (
    <NoSSR>
      <a href={buttonConfig.link} rel="noreferrer" target="_blank">
        <img
          {...{
            src: buttonConfig.logo,
            alt: buttonConfig.alt,
          }}
          width={180}
        />
      </a>
    </NoSSR>
  );
};

Builder.registerComponent(DownloadAppButton, {
  name: 'v2 App Store Download Button',
  inputs: [
    {
      name: 'isForIos',
      type: 'boolean',
      friendlyName: 'Button for iOS?',
    },
    {
      name: 'androidLogo',
      type: 'file',
    },
    {
      name: 'androidUrl',
      type: 'text',
    },
    {
      name: 'iosLogo',
      type: 'file',
    },
    {
      name: 'iosUrl',
      type: 'text',
    },
  ],
});

export default DownloadAppButton;
