import React, { useEffect, useState } from 'react';
import { Select } from 'glints-aries/lib/@next';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { CountryCodes, defaultCityProvinceAll } from 'src/common/enums';
import {
  DefaultLocation,
  NewSearchLocation,
} from 'src/global/models/NewSearchLocation';
import { useGenerateLocationQuery } from 'src/modules/Opportunities/pages/Explore/Components/SearchSection/generateSearchQuery';
import { useLocationSuggestionsQuery } from 'src/modules/Opportunities/pages/Explore/Components/SearchSection/LocationSearchField/useLocationSuggestionsQuery';

import PinIcon from './PinIcon';

const LocationSearchField: React.FC<{ countryCode: CountryCodes }> = ({
  countryCode,
}) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(
    DefaultLocation.getDefaultLocationAll(countryCode)
  );
  const [options, setOptions] = useState([]);

  const { setValue, watch } = useFormContext();
  const formCountryValue = watch('location')?.country;

  const suggestionLimit = 9;

  const [searchTerm] = useDebounce(inputValue, 500);

  useEffect(() => {
    if (formCountryValue) {
      setSelected(DefaultLocation.getDefaultLocationAll(countryCode));
    }
  }, [countryCode, formCountryValue]);

  const { data: locationResponse } = useLocationSuggestionsQuery(
    searchTerm,
    suggestionLimit,
    formCountryValue,
    { selectedLocation: selected, shouldShowL2: true }
  );

  useEffect(() => {
    if (locationResponse) {
      const newOptions = locationResponse.map(
        (location: NewSearchLocation) => ({
          value: location.label,
          label:
            location.label === defaultCityProvinceAll
              ? intl.formatMessage({
                  id: 'location.all',
                  defaultMessage: 'All Cities/Provinces',
                })
              : location.label,
        })
      );

      setOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationResponse]);

  const locationQuery = useGenerateLocationQuery(
    locationResponse,
    selected,
    formCountryValue
  );

  useEffect(() => {
    if (selected) {
      setValue('location', { ...locationQuery, country: formCountryValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelect = ({ value }: { value: string }) => {
    setSelected(locationResponse.find((location) => location.label === value));
  };

  return (
    <Select
      onSelect={handleSelect}
      options={options}
      selectedValues={[selected?.label ?? '']}
      width="100%"
      searchableProps={{
        inputValue,
        onInputChange: (value: string) => setInputValue(value),
      }}
      searchable={true}
      placeholder="Location"
      prefix={<PinIcon />}
    />
  );
};

export default LocationSearchField;
