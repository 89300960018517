import React from 'react';

const PinIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1377_1005)">
      <path
        d="M10 20.4921L4.69667 15.1888C3.64779 14.1399 2.93349 12.8035 2.64411 11.3486C2.35473 9.89379 2.50326 8.38579 3.07092 7.01535C3.63858 5.64491 4.59987 4.47358 5.83324 3.64947C7.0666 2.82536 8.51665 2.3855 10 2.3855C11.4834 2.3855 12.9334 2.82536 14.1668 3.64947C15.4001 4.47358 16.3614 5.64491 16.9291 7.01535C17.4968 8.38579 17.6453 9.89379 17.3559 11.3486C17.0665 12.8035 16.3522 14.1399 15.3033 15.1888L10 20.4921ZM14.125 14.0104C14.9408 13.1946 15.4963 12.1552 15.7213 11.0237C15.9463 9.89212 15.8308 8.71926 15.3892 7.6534C14.9477 6.58753 14.2 5.67652 13.2408 5.03557C12.2815 4.39462 11.1537 4.05252 10 4.05252C8.8463 4.05252 7.71851 4.39462 6.75924 5.03557C5.79997 5.67652 5.05229 6.58753 4.61076 7.6534C4.16923 8.71926 4.05368 9.89212 4.27871 11.0237C4.50374 12.1552 5.05926 13.1946 5.875 14.0104L10 18.1354L14.125 14.0104ZM10 11.5521C9.55798 11.5521 9.13405 11.3765 8.82149 11.0639C8.50893 10.7514 8.33334 10.3275 8.33334 9.88543C8.33334 9.44341 8.50893 9.01948 8.82149 8.70692C9.13405 8.39436 9.55798 8.21877 10 8.21877C10.442 8.21877 10.866 8.39436 11.1785 8.70692C11.4911 9.01948 11.6667 9.44341 11.6667 9.88543C11.6667 10.3275 11.4911 10.7514 11.1785 11.0639C10.866 11.3765 10.442 11.5521 10 11.5521Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_1377_1005">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.71875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PinIcon;
