import React from 'react';
import { Builder, withChildren } from '@builder.io/react';

import * as S from './AppPromotion.sc';

type BuilderProps = {
  children: React.ReactNode;
};

const AppPromotion = (props: BuilderProps) => {
  return <S.Wrapper>{props.children}</S.Wrapper>;
};

Builder.registerComponent(withChildren(AppPromotion), {
  name: 'v2 App Promotion',
});

export default AppPromotion;
