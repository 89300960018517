/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { ModalProps, TabModel, Tabs, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { defineMessage, useIntl } from 'react-intl';

import {
  AppLink,
  AppStoreIcon,
  AppStoreWrapper,
  ContentDescriptionWrapper,
  Description,
  QRImage,
  StyledModal,
  TabsWrapper,
} from './GlintsAppModal.sc';

interface TabContentProps {
  appStoreIcon: string;
  title: string;
  description: React.ReactNode;
}

const TabContent = ({ appStoreIcon, title, description }: TabContentProps) => (
  <>
    <ContentDescriptionWrapper>
      <AppStoreWrapper>
        <AppStoreIcon
          src={`images/landingv2/glints-app-modal/${appStoreIcon}-store-icon.png`}
        />
        <Typography variant="body2" color={Neutral.B18}>
          {title}
        </Typography>
      </AppStoreWrapper>
      {description}
    </ContentDescriptionWrapper>
    <QRImage src={`images/landingv2/glints-app-modal/qr-app.png`} />
  </>
);

const GlintsAppModalTabs = () => {
  const intl = useIntl();
  const [selected, setSelected] = useState(0);
  const tabs: TabModel[] = [
    { id: 'id-tab-1', content: 'Android' },
    { id: 'id-tab-2', content: 'iOS' },
  ];

  const tabContents = [
    <TabContent
      appStoreIcon="android"
      title={intl.formatMessage(
        defineMessage({
          id: 'homepage.glints_app_modal.title',
          defaultMessage: 'Glints: Job Search & Career',
        })
      )}
      description={
        <Description variant="body1" color={Neutral.B18}>
          {`${intl.formatMessage(
            defineMessage({
              id: 'homepage.glints_app_modal.description.scan_qr',
              defaultMessage: 'Scan the QR code to open',
            })
          )} `}
          <AppLink
            url="https://play.google.com/store/apps/details?id=com.glints.candidate"
            removeUnderline={true}
          >
            {intl.formatMessage(
              defineMessage({
                id: 'homepage.glints_app_modal.description.glints_app',
                defaultMessage: 'the Glints app',
              })
            )}
          </AppLink>{' '}
          {intl.formatMessage(
            defineMessage({
              id: 'homepage.glints_app_modal.description.better_experience',
              defaultMessage: 'for better user experience',
            })
          )}
        </Description>
      }
    />,
    <TabContent
      appStoreIcon="ios"
      title={intl.formatMessage(
        defineMessage({
          id: 'homepage.glints_app_modal.title',
          defaultMessage: 'Glints: Job Search & Career',
        })
      )}
      description={
        <Description variant="body1" color={Neutral.B18}>
          {`${intl.formatMessage(
            defineMessage({
              id: 'homepage.glints_app_modal.description.scan_qr',
              defaultMessage: 'Scan the QR code to open',
            })
          )} `}
          <AppLink
            url="https://apps.apple.com/id/app/glints-job-search-career/id1613169954"
            removeUnderline={true}
          >
            {intl.formatMessage(
              defineMessage({
                id: 'homepage.glints_app_modal.description.glints_app',
                defaultMessage: 'the Glints app',
              })
            )}
          </AppLink>{' '}
          {intl.formatMessage(
            defineMessage({
              id: 'homepage.glints_app_modal.description.better_experience',
              defaultMessage: 'for better user experience',
            })
          )}
        </Description>
      }
    />,
  ];

  return (
    <TabsWrapper>
      <Tabs
        tabs={tabs}
        selected={selected}
        onSelected={(i) => setSelected(i)}
        fitted={true}
      >
        {tabContents[selected]}
      </Tabs>
    </TabsWrapper>
  );
};

const GlintsAppModal = ({ isOpen, onClose }: ModalProps) => {
  const intl = useIntl();

  return (
    <StyledModal
      header={intl.formatMessage(
        defineMessage({
          id: 'homepage.glints_app_modal.header',
          defaultMessage: 'View on App',
        })
      )}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
    >
      <GlintsAppModalTabs />
    </StyledModal>
  );
};

export default GlintsAppModal;
