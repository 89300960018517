import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TextBlock = styled.span`
  text-decoration: underline;
  margin-right: 13px;
  margin-bottom: 16px;
  line-height: 140%;
`;
