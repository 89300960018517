import { Greyscale, PrimaryColor, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

import { ellipsis } from 'src/components/Ellipsis.sc';
import { ProfilePicture } from 'src/components/GlintsPicture';

export const EventCardContainer = styled.div<{
  isFeatured?: boolean;
}>`
  background: #ffffff;
  border: 1px solid ${Greyscale.lightgrey};
  border-radius: 12px;
  width: 300px;
  height: 290px;
  margin-right: 2px;

  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  }

  ${({ isFeatured }) =>
    isFeatured ? `box-shadow: 0px 1px 4px 1px rgba(45, 45, 45, 0.04);` : ``}

  @media (min-width: 991px) {
    width: 360px;
    height: 350px;
  }

  @media (min-width: 641px) and (max-width: 991px) {
    height: 320px;
  }
`;

export const CardBody = styled.div`
  padding: 12px 16px 16px;
  flex-grow: 1;
`;

export const CardContent = styled.div`
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  height: 100%;
  justify-content: space-between;
  @media (min-width: ${ScreenSize.tablet}px) {
    gap: 16px;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 300px;
  height: 290px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  @media screen and (min-width: 991px) {
    width: 360px;
    height: 350px;
  }
`;

export const UserImage = styled(ProfilePicture)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const SpeakerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  @media (min-width: ${ScreenSize.tablet}px) {
    margin-bottom: 8px;
  }
`;

export const SpeakerRating = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
  }
`;

export const SpeakerName = styled.p`
  flex-grow: 1;
  min-width: 0px;
  font-size: 12px;
  font-weight: 500;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SpeakerNameAndRatingContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, max-content) auto;
  align-items: center;
`;

export const SpeakerRole = styled.div`
  font-size: 12px;
  font-weight: 400;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
  }

  & b {
    font-weight: 600;
  }
  ${ellipsis(1)}
`;

export const EventTimeInfo = styled.div`
  display: flex;
  font-size: 12px;
  color: #575757;
  gap: 8px;
  margin-bottom: 4px;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
  }
`;

export const EventTypeInfo = styled.div`
  display: flex;
  font-size: 12px;
  color: #575757;
  gap: 8px;
  margin-bottom: 8px;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const SpeakerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  @media (min-width: ${ScreenSize.tablet}px) {
    margin-bottom: 16px;
  }
`;

export const DotSeperator = styled.span`
  margin-left: 4px;
  margin-right: 4px;
  &::after {
    content: '•';
  }
`;

export const BannerImageContainer = styled.div`
  position: relative;
  height: 110px;
  width: 100%;
  > div {
    border: none;
    cursor: initial !important;
  }
  > div > div {
    border-radius: 11px 11px 0 0;
    padding-top: calc(100% / 3);
  }
  height: 90px;
  @media (min-width: 991px) {
    height: 110px;
  }
`;

export const Type = styled.p`
  font-weight: 600;
  color: #bdbdbd;
  font-size: 12px;
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
  margin-bottom: 8px;
  white-space: initial;
  ${ellipsis(3)}
  max-height: 72px;

  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

export const UserImageContainer = styled.div`
  background: #fff;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  align-self: center;
  align-items: center;
`;

export const MoreSpeakersText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${Greyscale.devilsgrey};
  margin-left: 40px;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 14px;
  }
`;

export const Icon = styled.img`
  width: 16px;
`;

export const ExpertRatingIcon = styled.img.attrs({
  src: '/images/event/stars/filled.svg',
})`
  width: 12px;
  height: 12px;
`;

export const FinalPrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #2d2d2d;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 20px;
  }
`;

export const ActualAndFinalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  del {
    display: inline-block;
    font-weight: 400;
    color: ${Greyscale.devilsgrey};
    font-size: 12px;
  }
`;

export const DiscountPercentageLabel = styled.div`
  width: fit-content;
  padding: 2px 6px;
  text-align: center;
  background: ${PrimaryColor.glintsyellow};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
`;

export const PricingInfoContainerWithDiscount = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 8px;
  ${ActualAndFinalPrice} {
    flex-grow: 1;
  }
`;

export const PricingInfoContainerWithoutDiscount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const CardTag = styled.div`
  margin: 8px;
  > span {
    padding: 4px 10px;
    background-color: #666666;
    border-radius: 12px;
    font-size: 12px;
    margin-right: 8px;
    color: #ffffff;
  }

  @media (min-width: 641px) and (max-width: ${ScreenSize.tablet}px) {
    margin-bottom: 16px;
  }
`;

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  width: 80%;
`;
