import React from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import BannerImage from 'src/components/Cards/EventCard/BannerImage';
import { onDemandScheduleMessage } from 'src/modules/Event/messages';
import Speaker from 'src/modules/Events/Components/EventCard/Speaker';
import { ClassTypeNames } from 'src/modules/Events/constants';
import { Event } from 'src/modules/Events/types';

import * as S from './HotDealsCard.sc';

type Props = {
  event: Event;
  isFeatured?: boolean;
  gtmClassName?: string;
  showcaseType?: string;
  isCompact?: boolean;
};

const EventCard = ({ event, isFeatured, showcaseType }: Props) => {
  const {
    id,
    bannerPic,
    startDate,
    title,
    shortId,
    classType,
    speakers,
    categories,
  } = event;

  const { locale } = useIntl();
  const startDateMoment = moment(startDate).locale(locale);

  // Backend send the expert speakers sorted descending by the expert rating
  // 'null' string is needed for amplitude. Normal null doesn't work
  const highestExpertRating = speakers[0]?.expertRating?.finalRating ?? 'null';

  return (
    <S.EventCardContainer isFeatured={isFeatured}>
      <S.Card
        aria-label="Event Card"
        className="gtm-expert-class-data"
        data-gtm-expert-class-id={shortId}
        data-gtm-expert-class-showcase-type={showcaseType}
        data-gtm-expert-score={highestExpertRating}
      >
        <S.BannerImageContainer>
          <BannerImage eventId={id} src={bannerPic} alt={title} sizes="328px" />
        </S.BannerImageContainer>
        <S.CardBody>
          <S.CardContent>
            <div>
              <S.Title>
                {title.length > 40 ? `${title.slice(0, 40)}...` : title}
              </S.Title>
              <S.SpeakerInfo>
                <Speaker speakers={speakers} />
              </S.SpeakerInfo>
            </div>
            <S.EventWrapper>
              <S.EventTimeInfo>
                <S.Icon
                  src="/images/events/icons/new-calendar.svg"
                  alt="Calendar"
                />
                <p>
                  <Choose>
                    <When
                      condition={classType?.name === ClassTypeNames.ON_DEMAND}
                    >
                      <FormattedMessage {...onDemandScheduleMessage} />
                    </When>
                    <Otherwise>
                      {startDateMoment.format('dddd, DD MMM')}
                    </Otherwise>
                  </Choose>
                </p>
              </S.EventTimeInfo>
              <S.EventTimeInfo>
                <S.Icon src="/images/events/icons/clock.png" alt="Calendar" />
                {startDateMoment.format('HH:mm')}
              </S.EventTimeInfo>
            </S.EventWrapper>

            <S.CardTag>
              {categories.slice(0, 2).map((category, idx) => (
                <span key={idx}>{category.name}</span>
              ))}
            </S.CardTag>
          </S.CardContent>
        </S.CardBody>
      </S.Card>
    </S.EventCardContainer>
  );
};

export default React.memo(EventCard);
