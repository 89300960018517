import React from 'react';
import { Builder } from '@builder.io/react';

import { DataKey } from 'src/modules/Events/constants';
import { getHotDealShowcase } from 'src/modules/Events/graphql';
import { useGetEventsShowcase } from 'src/modules/Events/hooks/useGetEventsShowcase';

import * as S from './ExpertClassSection.sc';

const ExpertClassSection = () => {
  const { events } = useGetEventsShowcase(getHotDealShowcase, DataKey.HOT_DEAL);

  return (
    <S.StyledCarousel autoRotate={false} showArrows={false}>
      {events.map((event, idx) => (
        <S.HotDealsCard key={idx} event={event} />
      ))}
    </S.StyledCarousel>
  );
};

Builder.registerComponent(ExpertClassSection, {
  name: 'v2 GEC Section',
});

export default ExpertClassSection;
