import React from 'react';
import { PrimaryButton } from 'glints-aries/lib/@next';
import { FormattedMessage } from 'react-intl';

const SearchButton: React.FC = () => {
  return (
    <PrimaryButton aria-label="Search button" type="submit">
      <FormattedMessage
        id="homepage.search_section.search_button"
        defaultMessage="Search"
      />
    </PrimaryButton>
  );
};

export default SearchButton;
