import React from 'react';
import { Builder, withChildren } from '@builder.io/react';
import { IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';

import * as S from './SocialLinks.sc';

type BuilderProps = {
  instagramLink: string;
  twitterLink: string;
  facebookLink: string;
  linkedInLink: string;
  emailLink: string;
  tiktokLink: string;
  iconSize: number;
  children: React.ReactNode;
};

type SocialIcon = {
  iconName: IconNames;
  url: string;
};

const SocialLinks = (props: BuilderProps) => {
  const icons: Array<SocialIcon> = [
    { iconName: 'ri-instagram-line', url: props.instagramLink },
    { iconName: 'ri-twitter-x-fill', url: props.twitterLink },
    { iconName: 'ri-facebook-circle-line', url: props.facebookLink },
    { iconName: 'ri-linkedIn-line', url: props.linkedInLink },
    { iconName: 'ri-mail-line', url: props.emailLink },
    { iconName: 'ri-tiktok-line', url: props.tiktokLink },
  ];

  return (
    <>
      <S.Wrapper>
        {icons.map((icon, idx) => (
          <S.IconLink
            key={idx}
            href={icon.url || 'https://glints.com'}
            target="_blank"
            rel="noreferrer"
          >
            <S.StyledIcon
              name={icon.iconName}
              height={props.iconSize}
              width={props.iconSize}
            />
          </S.IconLink>
        ))}
      </S.Wrapper>
      <S.BottomContent>{props.children}</S.BottomContent>
    </>
  );
};

// NOTE: Using iconName as nested prop from Builder.io does not work as it
// returns undefined on the first render.

Builder.registerComponent(withChildren(SocialLinks), {
  name: 'v2 Social Links',
  canHaveChildren: true,
  inputs: [
    {
      name: 'instagramLink',
      type: 'text',
    },
    {
      name: 'twitterLink',
      type: 'text',
    },
    {
      name: 'facebookLink',
      type: 'text',
    },
    {
      name: 'linkedInLink',
      type: 'text',
    },
    {
      name: 'emailLink',
      type: 'text',
    },
    {
      name: 'tiktokLink',
      type: 'text',
    },
    {
      name: 'iconSize',
      type: 'number',
      defaultValue: 30,
    },
  ],
});

export default SocialLinks;
