import { defineMessage, FormattedMessageProps } from 'react-intl';

import { TicketCategory, TicketStatus } from './Components/Ticket/types';

export const locationLabelMessage = defineMessage({
  id: 'event.details.location',
  defaultMessage: 'Location',
});

export const onDemandScheduleMessage = defineMessage({
  id: 'class-detail-page.classinfo.date-time-on-demand',
  defaultMessage: 'Anytime',
});

export const eventScheduleLabelMessage = defineMessage({
  id: 'event.details.date.and.time',
  defaultMessage: 'Date & Time',
});

export const categoryLabelMessage = defineMessage({
  id: 'event.details.category',
  defaultMessage: 'Categories',
});

export const classTypeLabelMessage = defineMessage({
  id: 'class-detail-page.classinfo.class-type',
  defaultMessage: 'Class Type',
});

export const languageLabelMessage = defineMessage({
  id: 'class-detail-page.classinfo.language',
  defaultMessage: 'Language',
});

export const discountPercentMsg = defineMessage({
  id: 'expert-class.class-card.ticket.discount-label',
  defaultMessage: '{discountPercentage}% Off',
});

const soldOutMessage = defineMessage({
  id: 'ticket.button.sold-out',
  defaultMessage: 'sold out',
});

const salesEndedMessage = defineMessage({
  id: 'ticket.button.sales-ended',
  defaultMessage: 'sales ended',
});

const salesNotStartedMessage = defineMessage({
  id: 'ticket.button.sales-not-started',
  defaultMessage: 'sales not started',
});

export const messageMap: {
  [category in TicketCategory]: {
    [status in TicketStatus]?: FormattedMessageProps;
  };
} = {
  [TicketCategory.Free]: {
    [TicketStatus.Reserved]: defineMessage({
      id: 'ticket.button.view-order',
      defaultMessage: 'view order',
    }),
    [TicketStatus.SoldOut]: soldOutMessage,
    [TicketStatus.SalesEnded]: salesEndedMessage,
    [TicketStatus.SalesNotStarted]: salesNotStartedMessage,
    [TicketStatus.Available]: defineMessage({
      id: 'ticket.button.learn-now',
      defaultMessage: 'Learn Now',
    }),
  },
  [TicketCategory.Paid]: {
    [TicketStatus.SoldOut]: soldOutMessage,
    [TicketStatus.SalesEnded]: salesEndedMessage,
    [TicketStatus.SalesNotStarted]: salesNotStartedMessage,
    [TicketStatus.Available]: defineMessage({
      id: 'ticket.button.buy-paid-ticket',
      defaultMessage: 'buy ticket',
    }),
  },
};
