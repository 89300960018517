import React from 'react';
import { Builder } from '@builder.io/react';

import * as S from './UrgentJobsChip.sc';

type BuilderProps = {
  jobs: { jobName: string; link: string }[];
};

const UrgentJobsChip = (props: BuilderProps) => {
  if (!props.jobs || props.jobs?.length === 0) return <></>;

  return (
    <S.Wrapper>
      {props.jobs.map((job, idx) => (
        <a key={idx} href={job.link} target="_blank" rel="noreferrer">
          <S.StyledTag textColor="#017EB7">{job.jobName}</S.StyledTag>
        </a>
      ))}
    </S.Wrapper>
  );
};

Builder.registerComponent(UrgentJobsChip, {
  name: 'v2 Urgent Jobs Chip',
  inputs: [
    {
      name: 'jobs',
      type: 'list',
      subFields: [
        {
          name: 'jobName',
          type: 'text',
        },
        {
          name: 'link',
          type: 'text',
        },
      ],
    },
  ],
});

export default UrgentJobsChip;
