import React from 'react';
import { Builder } from '@builder.io/react';

import * as S from './GeoMap.sc';

type BuilderProps = {
  locations: { locationName: string; link: string }[];
};

const GeoMap = (props: BuilderProps) => {
  if (!props.locations || props.locations?.length === 0) return <></>;

  return (
    <S.Wrapper>
      {props.locations.map((loc, idx) => (
        <S.StyledLink
          key={idx}
          href={loc.link}
          rel="noreferrer"
          target="_blank"
        >
          {loc.locationName}
        </S.StyledLink>
      ))}
    </S.Wrapper>
  );
};

Builder.registerComponent(GeoMap, {
  name: 'v2 Geo Map',
  inputs: [
    {
      name: 'locations',
      type: 'list',
      subFields: [
        {
          name: 'locationName',
          type: 'text',
        },
        {
          name: 'link',
          type: 'text',
        },
      ],
    },
  ],
});

export default GeoMap;
