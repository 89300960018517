import { gql } from '@apollo/client';

// Need to define seperate literal because getExpertClasses query
// don't use gql as getExpertClasses query is sent via axios
// and not useQuery of @apollo/client
const BaseTicketTypeInfoStrLiteral = `
  fragment BaseTicketTypeInfo on ExpertClassTicketType {
    id
    price
    currency
    defaultDiscount @include(if: $includeDefaultDiscount) {
      applicableDiscountPercentage
      discountedPrice
      promotionCode
    }
  }
`;

const BaseEventFragmentLiteral = `
  fragment BaseEvent on ExpertClass {
    id
    title
    bannerPic
    startDate
    endDate
    shortId
    ticketTypes {
      ...BaseTicketTypeInfo
    }
    classType {
      id
      name
    }
    speakers {
      id
      profilePic
      ugcFullName
      presentExperiences {
        id
        title
        organization
      }
      expertRating {
        finalRating
      }
    }
    categories {
      name
    }
    numberOfSessions
  }
  ${BaseTicketTypeInfoStrLiteral}
`;

const BaseEventFragment = gql(BaseEventFragmentLiteral);

export const getPopularCategoryShowcase = gql`
  query getPopularCategoryShowcase {
    getPopularCategoryShowcase {
      id
      name
      totalUpcomingExpertClasses
    }
  }
`;

export const getExpertClasses = `
  query getExpertClasses(
    $data: QueryExpertClassesInput!
    $includeDefaultDiscount: Boolean! = false
  ) {
    getExpertClasses(data: $data) {
      data {
        ...BaseEvent
      }
      totalCount
    }
  }
  ${BaseEventFragmentLiteral}
`;

export const getFeaturedClassShowcase = gql`
  query getFeaturedClassShowcase($includeDefaultDiscount: Boolean! = false) {
    getFeaturedClassShowcase {
      ...BaseEvent
      descriptionRaw
    }
  }
  ${BaseEventFragment}
`;

export const getHotDealShowcase = gql`
  query getHotDealShowcase($includeDefaultDiscount: Boolean! = false) {
    getHotDealShowcase {
      ...BaseEvent
    }
  }
  ${BaseEventFragment}
`;

export const getSpecialProgramShowcase = gql`
  query getSpecialProgramShowcase($includeDefaultDiscount: Boolean! = false) {
    getSpecialProgramShowcase {
      ...BaseEvent
    }
  }
  ${BaseEventFragment}
`;
