import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useSelector } from 'react-redux';

import { Event } from 'src/modules/Events/types';

import { getIsDefaultPromoCodeEnabled } from '../Selectors';

export const useGetEventsShowcase = (query: DocumentNode, dataKey: string) => {
  const isDefaultDiscountEnabled = useSelector(getIsDefaultPromoCodeEnabled);
  const { data, loading, error } = useQuery<{
    [key: string]: Event[];
  }>(query, {
    variables: {
      includeDefaultDiscount: isDefaultDiscountEnabled,
    },
    fetchPolicy: 'network-only',
  });
  const events = (data && data[dataKey]) || [];

  return { events, loading, error };
};
