import React from 'react';
import { Builder } from '@builder.io/react';
import NoSSR from '@mpth/react-no-ssr';
import { ScreenSize } from 'glints-aries';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import * as S from './FooterNavLinks.sc';

type BuilderProps = {
  links: { text: string; url: string }[];
};

const FooterNavLinks = (props: BuilderProps) => {
  const isMobile = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);

  if (!props.links) return <></>;

  return (
    <NoSSR>
      <S.Wrapper isMobile={isMobile}>
        {props.links.map((link, idx) => (
          <a
            key={idx}
            href={link.url}
            target="_blank"
            style={{ color: '#fff' }}
            rel="noreferrer"
          >
            <span style={{ fontSize: 12 }}>{link.text}</span>
          </a>
        ))}
      </S.Wrapper>
    </NoSSR>
  );
};

Builder.registerComponent(FooterNavLinks, {
  name: 'v2 Footer Nav Links',
  inputs: [
    {
      name: 'links',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
      // onChange: () => {} // enforce 6 values
    },
  ],
});

export default FooterNavLinks;
