import { Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const WrapperMobile = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  box-shadow: 0px 0px 4px #aaa;
  padding: 20px 16px;
  gap: 16px;
  border-radius: 16px;
  width: 100%;
  min-height: 150px;
  color: #2d2d2d;
  box-shadow: 0px 16px 18px #ccc;
`;

export const UserLogoImageDivMobile = styled.div`
  flex: 0.1 1 0%;
`;

export const CardContentMobile = styled.div`
  display: flex;
  flex: 0.9;
  flex-direction: column;
  justify-content: space-between;
  line-height: 160%;
  color: #2d2d2d;
  padding-left: 16px;
`;

export const CardBottomMobile = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #666;
  letter-spacing: 1.5px;
  border-left: solid 1px #e9e9e9;
  padding-left: 4px;
  margin-top: 8px;
`;

export const WrapperTablet = styled.div<{ imageHeight: number }>`
  position: relative;
  padding: 5px;
  border-radius: 16px;
  max-width: 720px;
  min-height: 120px;
  color: #2d2d2d;
  height: ${(props) => props.imageHeight + 0.3 * props.imageHeight}px;
  box-shadow: 0px 16px 18px #ccc;
`;

export const UserLogoDivTablet = styled.div<{ imageHeight: number }>`
  position: absolute;
  left: ${(props) => props.imageHeight / -2}px;
  top: 50%;
  transform: translateY(-50%);
  height: ${(props) => props.imageHeight}px;
`;

export const UserContentTablet = styled.div<{ imageHeight: number }>`
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;
  height: 72px;
  padding-left: ${(props) => props.imageHeight / 2 + 14}px;
`;

export const UserContentTextTablet = styled.div`
  margin-top: 16px;
  margin-right: 24px;
  color: #2d2d2d;
`;

export const UserContentTextTypographyTablet = styled(Typography)`
  height: 72px;
  overflow: auto;
`;

export const UserContentBottomTablet = styled.div`
  letter-spacing: 1.5px;
  border-left: solid 1px #e9e9e9;
  padding-left: 4px;
  margin-top: 8px;
`;

export const WrapperDesktop = styled.div`
  position: relative;
  box-shadow: 0px 16px 18px #ccc;
  padding: 24px;
  border-radius: 16px;
  color: #2d2d2d;
`;

export const UserLogoDivDesktop = styled.div<{ imageHeight: number }>`
  position: absolute;
  top: ${(props) => props.imageHeight / -2}px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
`;

export const UserLogoImage = styled.img<{ imageHeight: number | string }>`
  border-radius: 100px;
  height: ${(props) => props.imageHeight}px;
  width: ${(props) => props.imageHeight}px;
  object-fit: cover;
  box-shadow: 0px 0px 0px 1px rgba(71, 71, 71, 0.05);
  filter: drop-shadow(0px 1px 3px rgba(63, 63, 68, 0.15));
  @media screen and (max-width: 640px) {
    margin-left: 20%;
  }
`;

export const UserContentDesktop = styled.div`
  color: #2d2d2d;
`;

export const UserContentBottomDesktop = styled.div`
  border-left: solid 1px #e9e9e9;
  padding-left: 4px;
  margin-top: 24px;
`;
