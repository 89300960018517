import React, { useContext, useEffect, useState } from 'react';
import { Builder } from '@builder.io/react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';

import { defaultCityProvinceAll } from 'src/common/enums';
import { useCountryData } from 'src/common/hooks/useCountryData';
import { generateSearchQuery } from 'src/modules/Opportunities/pages/Explore/Components/SearchSection/generateSearchQuery';
import { UpdatePastSearchesStateContext } from 'src/modules/Opportunities/pages/Explore/Components/SearchSuggestions/Context';
import { SearchRouterQueryKey } from 'src/modules/Opportunities/pages/Explore/types';

import KeywordSearchField from './KeywordSearchField';
import LocationSearchField from './LocationSearchField';
import SearchButton from './SearchButton';
import * as S from './SearchSection.sc';

type BuilderProps = {
  title: string;
};

const SearchSection = ({ title }: BuilderProps) => {
  const { countryCode } = useCountryData();
  const [isReady, setIsReady] = useState(false);
  const methods = useForm({
    defaultValues: {
      location: { country: countryCode, locationName: defaultCityProvinceAll },
    },
  });
  const router = useRouter();
  const { updatePastJobSearches } = useContext(UpdatePastSearchesStateContext);

  useEffect(() => {
    if (router.isReady) setIsReady(true);
  }, [router.isReady]);

  const onSubmit = (data: {
    keyword?: string;
    location?: {
      country?: string;
      searchCity?: string;
      locationId?: string;
      locationName?: string;
    };
  }) => {
    const routerQuery = generateSearchQuery(
      data.keyword,
      {
        [SearchRouterQueryKey.Country]: data.location?.country,
        [SearchRouterQueryKey.City]: data.location?.searchCity,
        [SearchRouterQueryKey.LocationId]: data.location?.locationId,
        [SearchRouterQueryKey.Location]: data.location?.locationName,
      },
      updatePastJobSearches
    );

    router.push(routerQuery);
  };

  if (!isReady) return <></>;

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(onSubmit)}>
        <S.Title>{title}</S.Title>
        <S.Container>
          <S.FieldWrapper>
            <KeywordSearchField />
          </S.FieldWrapper>
          <S.LocationFieldWrapper>
            <LocationSearchField countryCode={countryCode} />
          </S.LocationFieldWrapper>
          <SearchButton />
        </S.Container>
      </S.Form>
    </FormProvider>
  );
};

Builder.registerComponent(SearchSection, {
  name: 'SearchSection',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Find 25,000+ jobs in Indonesia',
    },
  ],
});

export default SearchSection;
