import React from 'react';

import { CoverImage } from 'src/components/GlintsPicture/CoverImage';
import {
  AssetType,
  GlintsPictureProps,
} from 'src/components/GlintsPicture/interfaces';

import { getRandomFallbackJobBannerPic } from '../../../common/AssetManager';
import { BannerImageContainer } from './BannerImage.sc';

interface Props extends Omit<GlintsPictureProps, 'name'> {
  eventId: string;
  onClick?: () => void;
  src: string;
  alt?: string;
  assetType?: AssetType;
}

const BannerImage: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  eventId,
  src,
  sizes,
  breakpoints,
  lazy,
  onClick,
  alt,
  assetType = AssetType.eventBannerPic,
}) => (
  <BannerImageContainer onClick={onClick}>
    <CoverImage
      className={className}
      alt={alt || 'Event Banner Picture'}
      assetType={assetType}
      fallback={getRandomFallbackJobBannerPic(eventId)}
      fallbackAssetType="default-banner-pic-large"
      name={src}
      sizes={sizes}
      breakpoints={breakpoints}
      lazy={lazy}
      aspectRatio={2}
    />
  </BannerImageContainer>
);

export default BannerImage;
