import React from 'react';
import { Builder } from '@builder.io/react';
import NoSSR from '@mpth/react-no-ssr';
import { isIOS } from 'react-device-detect';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import * as S from './DownloadAppButton.sc';

type BuilderProps = {
  isForIos: boolean;
  androidLogo: string;
  androidUrl: string;
  androidText: string;
  iosLogo: string;
  iosUrl: string;
  iosText: string;
};

const DownloadAppButton = (props: BuilderProps) => {
  const isMobileDevice = useMediaQuery(`(max-width: 991px)`);

  function getButtonConfig() {
    if (isMobileDevice) {
      if (isIOS) {
        return { text: props.iosText, logo: props.iosLogo, link: props.iosUrl };
      } else {
        return {
          text: props.androidText,
          logo: props.androidLogo,
          link: props.androidUrl,
        };
      }
    } else if (props.isForIos) {
      return { text: props.iosText, logo: props.iosLogo, link: props.iosUrl };
    } else {
      return {
        text: props.androidText,
        logo: props.androidLogo,
        link: props.androidUrl,
      };
    }
  }

  const buttonConfig = getButtonConfig();

  const Button = () => (
    <S.Wrapper>
      <S.LogoImage src={buttonConfig.logo} />
      <S.ButtonText dangerouslySetInnerHTML={{ __html: buttonConfig.text }} />
    </S.Wrapper>
  );

  if (isMobileDevice) {
    return (
      <NoSSR>
        <a href={buttonConfig.link} target="_blank" rel="noreferrer">
          <Button />
        </a>
      </NoSSR>
    );
  }

  return (
    <NoSSR>
      <Button />
    </NoSSR>
  );
};

Builder.registerComponent(DownloadAppButton, {
  name: 'v2 Download Button',
  inputs: [
    {
      name: 'isForIos',
      type: 'boolean',
      friendlyName: 'Button for iOS?',
    },
    {
      name: 'androidLogo',
      type: 'file',
    },
    {
      name: 'androidUrl',
      type: 'text',
    },
    {
      name: 'androidText',
      type: 'richText',
      defaultValue: 'Download Android app',
    },
    {
      name: 'iosLogo',
      type: 'file',
    },
    {
      name: 'iosUrl',
      type: 'text',
    },
    {
      name: 'iosText',
      type: 'richText',
      defaultValue: 'Download iOS app',
    },
  ],
});

export default DownloadAppButton;
