import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

export const useLocationIdFromSlug = (
  pathname: string,
  queryLocationId?: string
) => {
  const [response, setResponse] = useState('');

  const segments = useMemo(() => pathname.split('/').splice(1), [pathname]);
  const slug = useMemo(
    () => segments.slice(1).join('/').replace(/\/+$/, ''),
    [segments]
  );

  useEffect(() => {
    if (queryLocationId) {
      setResponse(queryLocationId);
    } else if (segments[0] === 'job-location' && slug) {
      axios
        .get(`/api/location/getId/${slug}`)
        .then((response) => {
          setResponse(response.data.data);
        })
        .catch((error) => {
          console.error(error);
          setResponse('');
        });
    }
  }, [queryLocationId, segments, slug]);

  return response;
};
