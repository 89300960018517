import React from 'react';
import { Builder } from '@builder.io/react';
import { Typography, TypographyProps } from 'glints-aries/lib/@next';

type BuilderProps = {
  text: string;
  variant: TypographyProps['variant'];
  color: string;
};

const BuilderTypography = (props: BuilderProps) => {
  return (
    <Typography variant={props.variant} color={props.color}>
      {props.text}
    </Typography>
  );
};

Builder.registerComponent(BuilderTypography, {
  name: 'Aries Typography',
  inputs: [
    {
      name: 'text',
      type: 'longText',
    },
    {
      name: 'variant',
      type: 'string',
    },
    {
      name: 'color',
      type: 'color',
      defaultValue: '#2d2d2d',
    },
  ],
});

export default BuilderTypography;
